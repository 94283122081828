<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h3 v-if="item">{{ $t("components.mainDialog.edit.title") }}</h3>
        <h3 v-else>{{ $t("components.mainDialog.add.title") }}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <!-- Name -->
            <v-col cols="12" class="mt-12">
              <v-text-field
                outlined
                :rules="requiredRules"
                :label="$t('components.labels.name')"
                maxlength="200"
                hide-details
                v-model="form.name"
                @change="
                  form.publicName === '' ? (form.publicName = form.name) : null
                "
              />
            </v-col>

            <!-- Public name -->
            <v-col cols="12">
              <v-text-field
                outlined
                :rules="requiredRules"
                :label="$t('components.labels.publicName')"
                maxlength="200"
                hide-details
                v-model="form.publicName"
              />
            </v-col>

            <!-- FM Referent -->
            <v-col cols="12">
              <SelectFMReferent
                :value="form.fm_referents"
                :is-clearable="false"
                :is-multiple="true"
                @input="onInputFMReferents"
              />
            </v-col>

            <!-- Account -->
            <v-col cols="12" v-if="!item">
              <SelectAccount
                :is-required="false"
                :value="form.account"
                :is-clearable="false"
                @input="onInputAccount"
              />
            </v-col>

            <!-- Project impact -->
            <v-col cols="12" v-if="!item">
              <SelectProjectImpact
                :is-multiple="false"
                :value="form.projectImpact"
                :account-id="form.account ? form.account.id : null"
                :is-required="false"
                :is-clearable="false"
                @input="onInputProjectImpact"
              />
            </v-col>

            <!-- Product -->
            <v-col cols="12" v-if="!item">
              <SelectProduct
                :account-id="form.account ? form.account.id : null"
                :is-required="false"
                :value="form.product"
                :is-clearable="false"
                @input="onInputProduct"
              />
            </v-col>

            <!-- Study -->
            <v-col cols="12" v-if="!item">
              <SelectStudy
                :is-required="false"
                :product-id="form.product ? form.product.id : null"
                :value="form.study"
                :is-clearable="false"
                @input="onInputStudy"
              />
            </v-col>

            <!-- Suppliers -->
            <v-col cols="12">
              <SelectSupplier
                :value="form.suppliers"
                :is-required="false"
                :return-object="true"
                :is-clearable="false"
                :is-multiple="true"
                @input="onInputSupplier"
              />
            </v-col>

            <!-- Type -->
            <v-col cols="12">
              <SelectGeneric
                :items="$t('constants.componentsTypes.list')"
                :value="form.type"
                :is-required="true"
                :return-object="true"
                :label="$t('components.labels.type')"
                @input="onInputType"
              />
            </v-col>

            <!-- Weight -->
            <v-col cols="12">
              <v-text-field
                hide-details
                outlined
                type="number"
                v-model="form.weight"
                :label="$t('components.labels.weight')"
              />
            </v-col>

            <v-col cols="12">
              <AlertBar />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
          class="mx-3"
          :is-loading-btn-save="isLoadingBtn.btnSave"
          @submit="customSubmit"
          @cancel="close"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog";
import formRules from "@/mixins/formRules";

export default {
  name: "ComponentMainDialog",

  mixins: [dialogMixin, formRules],

  components: {
    SelectStudy: () => import("@/components/Common/Inputs/SelectStudy"),
    SelectProduct: () => import("@/components/Common/Inputs/SelectProduct"),
    SelectAccount: () => import("@/components/Common/Inputs/SelectAccount"),
    SelectGeneric: () => import("@/components/Common/Inputs/SelectGeneric"),
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    ButtonBarFormDialog: () =>
      import("@/components/Common/Buttons/ButtonBarFormDialog"),
    SelectFMReferent: () =>
      import("@/components/Common/Inputs/SelectFMReferent"),
    SelectProjectImpact: () =>
      import("@/components/Common/Inputs/SelectProjectImpact"),
    SelectSupplier: () => import("@/components/Common/Inputs/SelectSupplier"),
  },

  props: {
    account: { type: Object, default: () => null },
    product: { type: Object, default: () => null },
    study: { type: Object, default: () => null },
    supplier: { type: Object, default: () => null },
    fm_referent: { type: Object, default: () => null },
    projectImpact: { type: Object, default: () => null },
  },

  data() {
    return {
      isLoadingBtn: { btnSave: false },

      defaultForm: {
        name: "",
        publicName: "",
        type: "TEXTILE",
        fm_referents: [
          this.fm_referent ?? {
            id: this.$session.get("id"),
            person: this.$session.get("person"),
          },
        ],
        projectImpact: this.projectImpact,
        account: this.account,
        product: this.product,
        study: this.study,
        suppliers: this.supplier ? [this.supplier] : [],
        weight: 0,
      },
    };
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        this.shortcutFormObjects([
          "fm_referents",
          "account",
          "product",
          "study",
          "suppliers",
          "projectImpact",
        ]);
        this.shortcutFormObjects(["type"], "value");
        this.form.weight = Number(this.form.weight);
        if (this.item) this.submitEdit();
        else this.submitAdd();
      }
    },

    submitAdd() {
      this.$http
        .post(`/components`, this.form, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("components.mainDialog.add.success")
          );
          this.close(true);
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
    },

    submitEdit() {
      this.$http
        .put(`/components/${this.item.id}`, this.form, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("components.mainDialog.edit.success")
          );
          this.close(true);
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
    },

    onInputFMReferents(val) {
      this.form.fm_referents = val;
    },

    onInputAccount(val) {
      this.form.account = val;
    },

    onInputProjectImpact(val) {
      this.form.projectImpact = val;
    },

    onInputProduct(val) {
      this.form.product = val;
    },

    onInputStudy(val) {
      this.form.study = val;
    },

    onInputSupplier(val) {
      this.form.suppliers = val;
    },

    onInputType(val) {
      this.form.type = val;
    },

    updateRawMaterials() {},

    addRawMaterial() {
      this.form.composition.push({
        material: null,
        percentage: 1,
      });
    },
  },

  computed: {},
};
</script>

<style scoped></style>
